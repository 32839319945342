$roboto: 'Roboto', sans-serif;
$opensans: 'Open Sans', sans-serif;
$poppins: 'Poppins', sans-serif;
$rubik: 'Rubik', sans-serif;
$quicksand: 'Quicksand', sans-serif;
$notosanstc: 'Noto Sans TC', sans-serif;
$hind: 'Hind', sans-serif;
$sora: 'Sora', sans-serif;
$oxygen: 'Oxygen', sans-serif;
$default-font: $poppins;

$regular-weight: 400;
$heavy-weight: 700;
$bold-weight: 800;
$native: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', Helvetica,
  Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;

$bg-color: #f2f2f2;
$fg-color: #ffffff;
$toast-bg-color: #fdcd00;
$view-order-bg-color: #fafafa;
$header-bg-color: #092340;
$submit-btn-bg-color: #0074bc;
$cancel-btn-bg-color: #b73946;
$button-color: #0080bb;
$button-disabled-color: #a7d2e5;
$spinner-background-color: rgba(255, 255, 255, 0.85);
$spinner-color: #a7d2e5;
$spinner-color2: #f3f3f3;
$toast2-bg-color: rgba(0, 0, 0, 0.5);

$base-font-size: 16px;
$base-font-color: #092340;
$text-actions-color: #72badb;
$form-border-color: #eaeaea;
$form-error: #fa755a;

$header-color-base: #84919f;
$header-color-secondary: #6a6c69;

$text-color-gray: #7d8898;
$text-color-blue: #0ea4de;
$text-color-bondi: #278189;
$text-color-goldenrod: #cc9700;
$text-color-ruby: #bc4146;
$text-color-inverted: #fafafa;
