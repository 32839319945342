/* Theme for Palette 1 */
// This theme is for Happyland venue: TE2_HQ_SAN

$color-primary-16: #213688;
$color-secondary-16: #278189;
$color-nav-16: #189ade;
$color-accent-16: #49a492;
$color-discount-16: #cc9700;
$color-error-16: #bc4146;
$color-canvas-16: #f4f4f4;
$color-card-16: #ffffff;
$color-dark-highlight-16: #cccccc;
$color-light-highlight-16: #d6d6d6;
$color-text-primary-16: #092340;
$color-text-secondary-16: #ffffff;
$color-text-tertiary-16: #6a6c69;

$color-button-disabled-16: lighten($color-accent-16, 40%);

.theme-palette-16 {
  .color-primary {
    color: $color-primary-16;
  }
  .bg-color-primary {
    background-color: $color-primary-16;
  }

  .color-secondary {
    color: $color-secondary-16;
  }
  .bg-color-secondary {
    background-color: $color-secondary-16;
  }
  .border-color-secondary {
    border-color: $color-secondary-16;
  }

  .toast-text-color {
    color: $color-primary-16;
  }

  .toast-bg-color {
    background-color: $color-discount-16;
  }

  .color-nav,
  a.color-nav:link,
  a.color-nav:visited,
  a.color-nav:hover {
    color: $color-nav-16;
  }

  .color-nav-header,
  a.color-nav-header:link,
  a.color-nav-header:visited,
  a.color-nav-header:hover {
    color: $color-text-secondary-16;
  }

  .color-checkmark {
    color: $color-primary-16;
  }

  .color-confirmation-icon {
    color: $color-light-highlight-16;
  }

  .color-confirmation-text {
    color: $color-text-primary-16;
  }

  .bg-color-nav {
    background-color: $color-nav-16;
  }
  .border-color-nav {
    border-color: $color-nav-16;
  }

  .color-accent {
    color: $color-accent-16;
  }
  .bg-color-accent,
  .bg-after-color-accent:after {
    background-color: $color-accent-16;
  }
  .border-color-accent {
    border-color: $color-accent-16;
  }
  .border-color-top-accent {
    border-top-color: $color-accent-16;
  }

  .color-discount {
    color: $color-discount-16;
  }
  .bg-color-discount {
    background-color: $color-discount-16;
  }

  .color-error {
    color: $color-error-16;
  }
  .bg-color-error {
    background-color: $color-error-16;
  }

  .color-canvas {
    color: $color-canvas-16;
  }
  .bg-color-canvas {
    background-color: $color-canvas-16;
  }

  .color-card {
    color: $color-card-16;
  }
  .bg-color-card,
  .content {
    background-color: $color-card-16;
  }

  .color-dark-highlight {
    color: $color-dark-highlight-16;
  }
  .bg-color-dark-highlight {
    background-color: $color-dark-highlight-16;
  }
  .border-color-dark-highlight {
    border-color: $color-dark-highlight-16;
  }

  .color-light-highlight {
    color: $color-light-highlight-16;
  }
  .bg-color-light-highlight {
    background-color: $color-light-highlight-16;
  }

  .color-text-primary {
    color: $color-text-primary-16;
  }

  .color-text-secondary {
    color: $color-text-secondary-16;
  }

  .color-text-tertiary,
  .title {
    color: $color-text-tertiary-16;
  }

  .color-disabled {
    color: $color-dark-highlight-16;
  }

  // Specific form elements
  button {
    color: $color-card-16;
    background-color: $color-accent-16;
  }
}

:export {
  color_primary_theme_16: $color-primary-16;
  color_secondary_theme_16: $color-secondary-16;
  color_nav_theme_16: $color-nav-16;
  color_accent_theme_16: $color-accent-16;
  color_discount_theme_16: $color-discount-16;
  color_error_theme_16: $color-error-16;
  color_canvas_theme_16: $color-canvas-16;
  color_card_theme_16: $color-card-16;
  color_dark_highlight_theme_16: $color-dark-highlight-16;
  color_light_highlight_theme_16: $color-light-highlight-16;
  color_text_primary_theme_16: $color-text-primary-16;
  color_text_secondary_theme_16: $color-text-secondary-16;
  color_text_tertiary_theme_16: $color-text-tertiary-16;
  color_button_disabled_theme_16: $color-button-disabled-16;
}
