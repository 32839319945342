@import '../../styles/main';

.counter-wrapper {
  .counter {
    display: flex;
    flex-direction: row;

    .button,
    .quantity-wrapper {
      height: 40px;
      width: 40px;
      text-transform: uppercase;
      border: none;
      font-size: rem(18px);
    }

    .quantity-wrapper {
      span {
        font-weight: $bold-weight;
        font-size: rem(20px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }
  }
}
