/* Theme for Palette 4 */

$color-primary-4: #0f2c41;
$color-secondary-4: #0278bc;
$color-nav-4: #86c0e4;
$color-accent-4: #2c698d;
$color-discount-4: #cc9700;
$color-error-4: #bc4146;
$color-canvas-4: #f2f2f2;
$color-card-4: #ffffff;
$color-dark-highlight-4: #cccccc;
$color-light-highlight-4: #d6d6d6;
$color-text-primary-4: #000000;
$color-text-secondary-4: #ffffff;
$color-text-tertiary-4: #000000;

$color-button-disabled-4: lighten($color-accent-4, 40%);

.theme-palette-4 {
  .color-primary {
    color: $color-primary-4;
  }
  .bg-color-primary {
    background-color: $color-primary-4;
  }

  .color-secondary {
    color: $color-secondary-4;
  }
  .bg-color-secondary {
    background-color: $color-secondary-4;
  }
  .border-color-secondary {
    border-color: $color-secondary-4;
  }

  .toast-text-color {
    color: $color-primary-4;
  }

  .toast-bg-color {
    background-color: $color-discount-4;
  }

  .color-nav,
  a.color-nav:link,
  a.color-nav:visited,
  a.color-nav:hover {
    color: $color-nav-4;
  }

  .color-nav-header,
  a.color-nav-header:link,
  a.color-nav-header:visited,
  a.color-nav-header:hover {
    color: $color-nav-4;
  }

  .color-checkmark {
    color: $color-primary-4;
  }

  .color-confirmation-icon {
    color: $color-light-highlight-4;
  }

  .color-confirmation-text {
    color: $color-text-primary-4;
  }

  .bg-color-nav {
    background-color: $color-nav-4;
  }
  .border-color-nav {
    border-color: $color-nav-4;
  }

  .color-accent {
    color: $color-accent-4;
  }
  .bg-color-accent,
  .bg-after-color-accent:after {
    background-color: $color-accent-4;
  }
  .border-color-accent {
    border-color: $color-accent-4;
  }
  .border-color-top-accent {
    border-top-color: $color-accent-4;
  }

  .color-discount {
    color: $color-discount-4;
  }
  .bg-color-discount {
    background-color: $color-discount-4;
  }

  .color-error {
    color: $color-error-4;
  }
  .bg-color-error {
    background-color: $color-error-4;
  }

  .color-canvas {
    color: $color-canvas-4;
  }
  .bg-color-canvas {
    background-color: $color-canvas-4;
  }

  .color-card {
    color: $color-card-4;
  }
  .bg-color-card,
  .content {
    background-color: $color-card-4;
  }

  .color-dark-highlight {
    color: $color-dark-highlight-4;
  }
  .bg-color-dark-highlight {
    background-color: $color-dark-highlight-4;
  }
  .border-color-dark-highlight {
    border-color: $color-dark-highlight-4;
  }

  .color-light-highlight {
    color: $color-light-highlight-4;
  }
  .bg-color-light-highlight {
    background-color: $color-light-highlight-4;
  }

  .color-text-primary {
    color: $color-text-primary-4;
  }

  .color-text-secondary {
    color: $color-text-secondary-4;
  }

  .color-text-tertiary,
  .title {
    color: $color-text-tertiary-4;
  }

  .color-disabled {
    color: $color-dark-highlight-4;
  }

  // Specific form elements
  button {
    color: $color-card-4;
    background-color: $color-accent-4;
  }
}

:export {
  color_primary_theme_4: $color-primary-4;
  color_secondary_theme_4: $color-secondary-4;
  color_nav_theme_4: $color-nav-4;
  color_accent_theme_4: $color-accent-4;
  color_discount_theme_4: $color-discount-4;
  color_error_theme_4: $color-error-4;
  color_canvas_theme_4: $color-canvas-4;
  color_card_theme_4: $color-card-4;
  color_dark_highlight_theme_4: $color-dark-highlight-4;
  color_light_highlight_theme_4: $color-light-highlight-4;
  color_text_primary_theme_4: $color-text-primary-4;
  color_text_secondary_theme_4: $color-text-secondary-4;
  color_text_tertiary_theme_4: $color-text-tertiary-4;
  color_button_disabled_theme_4: $color-button-disabled-4;
}
