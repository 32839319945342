@import '../../styles/main';

.modifier-group-wrapper {
  margin: rem(40px) 0;

  .modifier-container {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
  }
}

.modifier-group-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modifier-container {
  .modifier-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    height: 24px;
    font-size: rem(22px);
    padding-left: 35px;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
      cursor: pointer;

      &:checked {
        & ~ .checkmark {
          border-width: 2px;
          border-style: solid;

          &:after {
            display: block;

            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }

          &.round {
            &:after {
              display: block;
              left: 0;
              top: 0;
              width: 12px;
              height: 12px;
              border-width: 3px;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      border-width: 2px;
      border-style: solid;

      &.round {
        border-radius: 50%;
      }

      &:after {
        content: '';
        position: absolute;
        display: none;
      }

      &.disabled {
        border-color: #eee;
      }
    }

    .modifier-text {
      font-size: rem(14px);
    }

    .modifier-price {
      font-size: rem(14px);
      font-weight: $bold-weight;
      margin-left: 12px;
      opacity: 0.5;
    }
  }
}
