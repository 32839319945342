@import '../../styles/main';

html {
  font-size: $base-font-size;
}

html,
body,
#root,
.app {
  width: 100%;
  margin: 0;
  height: 99vh;
}

.app {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;

  & > *::-webkit-scrollbar {
    width: 0;
    background: transparent;
    height: 0;
    -webkit-appearance: none;
  }

  .content-wrapper {
    /*
    *  The margin here should be synced with the height of the page button
    *  and the total height of the header
    */
    padding-top: 64px;
    .toast-open {
      padding-top: 115px;
    }
  }

  &.billing-information,
  &.order-details,
  &.view-order,
  &.error {
    .content-wrapper {
      margin-top: calc(64px + constant(safe-area-inset-top));
      margin-top: calc(64px + env(safe-area-inset-top));
    }
  }

  .header-wrapper {
    position: fixed;
    width: 100%;
    z-index: 200;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }

  .content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;

    &.disabled {
      pointer-events: none;
    }

    /*
     * Disable elastic scroll in ios Safari
     */

    -webkit-overflow-scrolling: auto !important;

    .title {
      text-transform: uppercase;
      margin: rem(30px) rem(16px) rem(16px);
      font-weight: $bold-weight;
      font-size: rem(14px);
    }

    .content {
      border: solid #e4e4e4 rem(1px);
    }
  }

  .header-wrapper,
  .content-wrapper {
    box-sizing: border-box;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
  }
}
