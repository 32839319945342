@import '../../styles/main';

.siriusware-info {
  .content {
    padding: rem(16px);
  }

  .sign-in-text {
    margin: 0 0 rem(15px) 0;
  }

  button {
    width: 100%;
    height: 52px;
    padding: 0;
  }
}
