@import '../../styles/main';

.discount-wrapper {
  .two-column-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: rem(30px) rem(16px) rem(16px);

    .title {
      margin: 0 !important;
    }
  }
  .about-discount {
    background: none;
    .text {
      margin-left: rem(5px);
      text-transform: capitalize;
      font-size: rem(16px);
      color: $text-color-blue;
    }
  }
  .content {
    padding: rem(16px) rem(16px) 0 rem(16px);
    position: relative;
    .apply-button {
      position: absolute;
      width: rem(30px);
      top: rem(36px);
      right: rem(38px);
      font-size: rem(16px);
      text-transform: uppercase;
      cursor: pointer;
    }
    .form-group {
      margin-bottom: 1.25rem;
    }
  }
}

.popup-wrapper {
  .discount-description {
    margin: 0 rem(20px);
  }
}
