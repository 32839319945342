/* Default theme */

$color-primary: #092340;
$color-secondary: #278189;
$color-nav: #189ade;
$color-accent: #0080bb;
$color-discount: #cc9700;
$color-error: #bc4146;
$color-canvas: #f4f4f4;
$color-card: #ffffff;
$color-dark-highlight: #cccccc;
$color-light-highlight: #d6d6d6;
$color-text-primary: #092340;
$color-text-secondary: #ffffff;
$color-text-tertiary: #6a6c69;

$color-button-disabled: lighten($color-accent, 50%);

// Font configurations can inject CSS that overrides these font families
.font-primary,
html,
label,
.title,
.header-text,
.header-secondary-text {
  font-family: $poppins;
}

.font-secondary,
.secondary-text,
input {
  font-family: $native;
}

// Required to set the color of SVGs
svg {
  fill: currentColor;
}

// Colors that can be overridden by themes

.color-primary {
  color: $color-primary;
}
.bg-color-primary {
  background-color: $color-primary;
}

.color-secondary {
  color: $color-secondary;
}
.bg-color-secondary {
  background-color: $color-secondary;
}
.border-color-secondary {
  border-color: $color-secondary;
}

.toast-text-color {
  color: $color-primary;
}

.toast-bg-color {
  background-color: $color-discount;
}

.color-nav,
a.color-nav:link,
a.color-nav:visited,
a.color-nav:hover {
  color: $color-nav;
}
.color-nav-header,
a.color-nav-header:link,
a.color-nav-header:visited,
a.color-nav-header:hover {
  color: $color-nav;
}

.color-checkmark {
  color: $color-primary;
}

.color-confirmation-icon {
  color: $color-light-highlight;
}

.color-confirmation-text {
  color: $color-text-primary;
}

.bg-color-nav {
  background-color: $color-nav;
}
.border-color-nav {
  border-color: $color-nav;
}

.color-accent {
  color: $color-accent;
}
.bg-color-accent,
.bg-after-color-accent:after {
  background-color: $color-accent;
}
.border-color-accent {
  border-color: $color-accent;
}
.border-color-top-accent {
  border-top-color: $color-accent;
}

.color-discount {
  color: $color-discount;
}
.bg-color-discount {
  background-color: $color-discount;
}

.color-error {
  color: $color-error;
}
.bg-color-error {
  background-color: $color-error;
}

.color-canvas {
  color: $color-canvas;
}
.bg-color-canvas {
  background-color: $color-canvas;
}

.color-card {
  color: $color-card;
}
.bg-color-card,
.content {
  background-color: $color-card;
}

.color-dark-highlight {
  color: $color-dark-highlight;
}
.bg-color-dark-highlight {
  background-color: $color-dark-highlight;
}
.border-color-dark-highlight {
  border-color: $color-dark-highlight;
}

.color-light-highlight {
  color: $color-light-highlight;
}
.bg-color-light-highlight {
  background-color: $color-light-highlight;
}

.color-text-primary,
.title {
  color: $color-text-primary;
}

.color-text-secondary {
  color: $color-text-secondary;
}

.color-text-tertiary {
  color: $color-text-tertiary;
}

.color-disabled {
  color: $color-dark-highlight;
}

// Specific form elements
button {
  color: $color-card;
  background-color: $color-accent;
}

.color-button-disabled,
button:disabled,
button.disabled {
  opacity: 0.5;
}
