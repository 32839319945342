/* Theme for Palette 12 */
/* Theme for Sugarbush Venue */

$color-primary-12: #ba1a22;
$color-secondary-12: #ba1a22;
$color-nav-12: #ffffff;
$color-accent-12: #453929;
$color-discount-12: #0f4c81;
$color-error-12: #e50000;
$color-canvas-12: #e4e0df;
$color-card-12: #ffffff;
$color-dark-highlight-12: #b9babb;
$color-light-highlight-12: #cacbcc;
$color-text-primary-12: #000000;
$color-text-secondary-12: #ffffff;
$color-text-tertiary-12: #000000;

$color-button-disabled-12: lighten($color-accent-12, 40%);

.theme-palette-12 {
  .color-primary {
    color: $color-primary-12;
  }
  .bg-color-primary {
    background-color: $color-primary-12;
  }

  .color-secondary {
    color: $color-secondary-12;
  }
  .bg-color-secondary {
    background-color: $color-secondary-12;
  }
  .border-color-secondary {
    border-color: $color-secondary-12;
  }

  .toast-text-color {
    color: $color-primary-12;
  }

  .toast-bg-color {
    background-color: $color-dark-highlight-12;
  }

  .color-nav,
  a.color-nav:link,
  a.color-nav:visited,
  a.color-nav:hover {
    color: $color-nav-12;
  }

  .color-nav-header,
  a.color-nav-header:link,
  a.color-nav-header:visited,
  a.color-nav-header:hover {
    color: $color-nav-12;
  }

  .color-checkmark {
    color: $color-primary-12;
  }

  .color-confirmation-icon {
    color: $color-light-highlight-12;
  }

  .color-confirmation-text {
    color: $color-text-primary-12;
  }

  .bg-color-nav {
    background-color: $color-nav-12;
  }
  .border-color-nav {
    border-color: $color-nav-12;
  }

  .color-accent {
    color: $color-accent-12;
  }
  .bg-color-accent,
  .bg-after-color-accent:after {
    background-color: $color-accent-12;
  }
  .border-color-accent {
    border-color: $color-accent-12;
  }
  .border-color-top-accent {
    border-top-color: $color-accent-12;
  }

  .color-discount {
    color: $color-discount-12;
  }
  .bg-color-discount {
    background-color: $color-discount-12;
  }

  .color-error {
    color: $color-error-12;
  }
  .bg-color-error {
    background-color: $color-error-12;
  }

  .color-canvas {
    color: $color-canvas-12;
  }
  .bg-color-canvas {
    background-color: $color-canvas-12;
  }

  .color-card {
    color: $color-card-12;
  }
  .bg-color-card,
  .content {
    background-color: $color-card-12;
  }

  .color-dark-highlight {
    color: $color-dark-highlight-12;
  }
  .bg-color-dark-highlight {
    background-color: $color-dark-highlight-12;
  }
  .border-color-dark-highlight {
    border-color: $color-dark-highlight-12;
  }

  .color-light-highlight {
    color: $color-light-highlight-12;
  }
  .bg-color-light-highlight {
    background-color: $color-light-highlight-12;
  }

  .color-text-primary {
    color: $color-text-primary-12;
  }

  .color-text-secondary {
    color: $color-text-secondary-12;
  }

  .color-text-tertiary,
  .title {
    color: $color-text-tertiary-12;
  }

  .color-disabled {
    color: $color-dark-highlight-12;
  }

  // Specific form elements
  button {
    color: $color-card-12;
    background-color: $color-accent-12;
  }
}

:export {
  color_primary_theme_12: $color-primary-12;
  color_secondary_theme_12: $color-secondary-12;
  color_nav_theme_12: $color-nav-12;
  color_accent_theme_12: $color-accent-12;
  color_discount_theme_12: $color-discount-12;
  color_error_theme_12: $color-error-12;
  color_canvas_theme_12: $color-canvas-12;
  color_card_theme_12: $color-card-12;
  color_dark_highlight_theme_12: $color-dark-highlight-12;
  color_light_highlight_theme_12: $color-light-highlight-12;
  color_text_primary_theme_12: $color-text-primary-12;
  color_text_secondary_theme_12: $color-text-secondary-12;
  color_text_tertiary_theme_12: $color-text-tertiary-12;
  color_button_disabled_theme_12: $color-button-disabled-12;
}
