@import '../../styles/main';
.title {
  opacity: 0.5;
}

.order-notifications-header {
  font-size: rem(18px);
  color: black;
  font-weight: 700;
}

.order-notifications-content {
  font-size: rem(14px);
  color: black;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(20px);

  .show-more-less-clickable {
    color: $text-color-blue;
  }
  .order-notifications-text {
    width: 90%;
  }
  .info-icon {
    height: 18px;
    width: 18px;
    color: $text-color-blue;
  }

  .tooltip-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}

.phone-input {
  border: 1px solid #ccc;
  padding: 10px;
}

.ada-checkbox {
  padding-top: 16px;
  .ada-checkbox-label {
    padding-left: 8px;
    color: black;
    font-weight: normal;
    text-transform: none;
  }
}

.error-message {
  font-size: 0.75rem;
  padding-top: 6px;
  opacity: 0.5;
  font-weight: 400;
}
