@import '../../styles/main';

.popup-wrapper {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  position: fixed;
  z-index: 900;

  &.nutrition-info {
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
  }
  .nutrition-popup-content {
    flex: 1;
    height: calc(100% - 64px);

    .nutrition-popup-pdf {
      height: 100%;
      width: 100%;
      overflow: auto;
    }
  }

  &.show {
    transition-property: all;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    top: 0;
    bottom: 100%;
    max-height: 100%;
  }

  &.hide {
    transition-property: all;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    top: 100%;
    bottom: 0;
    max-height: 0;
    overflow: hidden;

    .button-wrapper {
      display: none;
    }
  }

  .close-button-wrapper.shadow-bg {
    position: absolute;
    width: 100%;
    height: rem(104px);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    z-index: 29;
  }

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30;
    padding-top: unquote('max(#{rem(24px)}, constant(safe-area-inset-top))');
    padding-right: unquote(
      'max(#{rem(24px)}, constant(safe-area-inset-right))'
    );
    padding-top: unquote('max(#{rem(24px)}, env(safe-area-inset-top))');
    padding-right: unquote('max(#{rem(24px)}, env(safe-area-inset-right))');
    cursor: pointer;
  }

  .popup-content-wrapper {
    overflow-y: scroll;

    @media screen and (min-width: 414px) and (orientation: portrait) {
      height: 90%;
    }

    @media screen and (max-width: 413px) and (orientation: portrait) {
      height: 87%;
    }

    @media screen and (max-width: 823px) and (orientation: landscape) {
      height: 78%;
    }

    @media screen and (min-width: 824px) and (orientation: landscape) {
      height: 90%;
    }

    .image-wrapper {
      position: relative;
      max-width: 100vw;
      overflow: hidden;

      &.hide {
        visibility: hidden;
        height: rem(48px);
      }

      img {
        width: 100vw;
        height: 33vh;
        object-fit: contain;
        position: relative;
        z-index: 20;
      }
    }

    .details-wrapper {
      padding: rem(16px) rem(20px);

      .item-name-wrapper {
        font-size: rem(24px);

        .item-name {
          margin: 0;
        }
      }

      .item-content-wrapper {
        .item-content {
          font-family: $native;
        }
      }

      .item-age-restriction-label {
        color: $color-error;
      }

      .price-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: rem(20px);

        .nutritional-link-wrapper {
          display: flex;
          align-items: center;
          color: #08adb2;
          font-size: rem(16px);
          font-weight: 400;
          cursor: pointer;

          a {
            padding-right: 8px;
            color: #08adb2;
            font-size: rem(16px);
            font-weight: 400;
            text-decoration: none;
          }
        }
      }
    }
  }

  .spinner-container {
    background: none;
    z-index: 10;
  }
}
