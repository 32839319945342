@import '../../styles/main';

.store-name-wrapper {
  height: rem(40px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: rem(12px) 0;

  .store-name {
    width: 100%;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-size: rem(18px);
  }

  .left-button-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: unquote('max(#{rem(24px)}, constant(safe-area-inset-left))');
    padding-left: unquote('max(#{rem(24px)}, env(safe-area-inset-left))');
  }

  .right-button-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    // https://github.com/sass/sass/issues/2378

    padding-right: unquote(
      'max(#{rem(24px)}, constant(safe-area-inset-right))'
    );
    padding-right: unquote('max(#{rem(24px)}, env(safe-area-inset-right))');
  }

  .left-button-wrapper,
  .right-button-wrapper {
    padding-top: unquote('max(#{rem(24px)}, constant(safe-area-inset-top))');
    padding-top: unquote('max(#{rem(24px)}, env(safe-area-inset-top))');
  }
}
