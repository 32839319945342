@import '../../styles/main';

.popup-header-wrapper {
  height: rem(40px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(12px) 0;

  .popup-header {
    width: 100%;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-size: rem(18px);
  }

  .close-popup {
    position: absolute;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30;
    cursor: pointer;
  }

  .left-button-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: unquote('max(#{rem(24px)}, constant(safe-area-inset-left))');
    padding-left: unquote('max(#{rem(24px)}, env(safe-area-inset-left))');
  }

  .left-button-wrapper,
  .right-button-wrapper {
    padding-top: unquote('max(#{rem(24px)}, constant(safe-area-inset-top))');
    padding-top: unquote('max(#{rem(24px)}, env(safe-area-inset-top))');
  }
}
