@import '../../styles/main';

.toast2-wrapper {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;

  &.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.4s, opacity 0.2s linear;
  }

  &.show {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.4s, opacity 0.2s linear;
  }

  .toast2 {
    position: fixed;
    bottom: -25%;
    box-sizing: border-box;
    padding: rem(15px);
    width: 100%;
    z-index: 1000;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &.show {
      transition-property: all;
      transition-duration: 0.6s;
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      bottom: 0;
    }

    &.hide {
      transition-property: all;
      transition-duration: 0.6s;
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      bottom: -25%;

      .button-wrapper {
        display: none;
      }
    }

    .title {
      text-transform: uppercase;
      font-weight: $bold-weight;
      font-size: rem(22px);
      margin: 0 0 rem(10px) 0;
      color: $color-text-primary;

      &.plain {
        font-weight: $heavy-weight;
        text-transform: none;
        font-size: rem(24px);
        margin: rem(10px) 0;
      }
    }

    .message {
      margin: 0;
      font-size: rem(16px);
      line-height: rem(22px);

      &.small {
        font-size: rem(13px);
        line-height: 1.23;
        margin: rem(9px) 0;
      }
    }

    .actions {
      justify-content: flex-end;
    }

    .actions-left {
      justify-content: flex-start;
    }

    .actions,
    .actions-left {
      display: flex;

      &.column {
        flex-direction: column;
      }

      .no {
        margin-right: rem(30px);
      }

      button {
        text-transform: uppercase;
        font-weight: $bold-weight;
        margin: rem(15px) 0;
        cursor: pointer;

        &.x-close {
          opacity: 0.5;
          padding-left: 0;
          padding-right: 0;
        }

        &.full-width {
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
          font-weight: $regular-weight;
          padding: rem(16px) 0;
          margin: rem(9px) 0;

          &.inverted {
            border-style: solid;
            border-width: 2px;
          }

          &:hover {
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }
}
