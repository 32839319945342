/* Theme for Palette 1 */
// This theme is for Happyland venue: Six Flags Over Texas
$color-primary-17: #d60b12;
$color-secondary-17: #d60b12;
$color-nav-17: #189ade;
$color-accent-17: #79ba4b;
$color-discount-17: #cc9700;
$color-error-17: #bc4146;
$color-canvas-17: #f4f4f4;
$color-card-17: #ffffff;
$color-dark-highlight-17: #cccccc;
$color-light-highlight-17: #d6d6d6;
$color-text-primary-17: #092340;
$color-text-secondary-17: #ffffff;
$color-text-tertiary-17: #6a6c69;

$color-button-disabled-17: lighten($color-accent-17, 40%);

.theme-palette-17 {
  .color-primary {
    color: $color-primary-17;
  }
  .bg-color-primary {
    background-color: $color-primary-17;
  }

  .color-secondary {
    color: $color-secondary-17;
  }
  .bg-color-secondary {
    background-color: $color-secondary-17;
  }
  .border-color-secondary {
    border-color: $color-secondary-17;
  }

  .toast-text-color {
    color: $color-primary-17;
  }

  .toast-bg-color {
    background-color: $color-discount-17;
  }

  .color-nav,
  a.color-nav:link,
  a.color-nav:visited,
  a.color-nav:hover {
    color: $color-nav-17;
  }

  .color-nav-header,
  a.color-nav-header:link,
  a.color-nav-header:visited,
  a.color-nav-header:hover {
    color: $color-text-secondary-17;
  }

  .color-checkmark {
    color: $color-primary-17;
  }

  .color-confirmation-icon {
    color: $color-light-highlight-17;
  }

  .color-confirmation-text {
    color: $color-text-primary-17;
  }

  .bg-color-nav {
    background-color: $color-nav-17;
  }
  .border-color-nav {
    border-color: $color-nav-17;
  }

  .color-accent {
    color: $color-accent-17;
  }
  .bg-color-accent,
  .bg-after-color-accent:after {
    background-color: $color-accent-17;
  }
  .border-color-accent {
    border-color: $color-accent-17;
  }
  .border-color-top-accent {
    border-top-color: $color-accent-17;
  }

  .color-discount {
    color: $color-discount-17;
  }
  .bg-color-discount {
    background-color: $color-discount-17;
  }

  .color-error {
    color: $color-error-17;
  }
  .bg-color-error {
    background-color: $color-error-17;
  }

  .color-canvas {
    color: $color-canvas-17;
  }
  .bg-color-canvas {
    background-color: $color-canvas-17;
  }

  .color-card {
    color: $color-card-17;
  }
  .bg-color-card,
  .content {
    background-color: $color-card-17;
  }

  .color-dark-highlight {
    color: $color-dark-highlight-17;
  }
  .bg-color-dark-highlight {
    background-color: $color-dark-highlight-17;
  }
  .border-color-dark-highlight {
    border-color: $color-dark-highlight-17;
  }

  .color-light-highlight {
    color: $color-light-highlight-17;
  }
  .bg-color-light-highlight {
    background-color: $color-light-highlight-17;
  }

  .color-text-primary {
    color: $color-text-primary-17;
  }

  .color-text-secondary {
    color: $color-text-secondary-17;
  }

  .color-text-tertiary,
  .title {
    color: $color-text-tertiary-17;
  }

  .color-disabled {
    color: $color-dark-highlight-17;
  }

  // Specific form elements
  button {
    color: $color-card-17;
    background-color: $color-accent-17;
  }
}

:export {
  color_primary_theme_17: $color-primary-17;
  color_secondary_theme_17: $color-secondary-17;
  color_nav_theme_17: $color-nav-17;
  color_accent_theme_17: $color-accent-17;
  color_discount_theme_17: $color-discount-17;
  color_error_theme_17: $color-error-17;
  color_canvas_theme_17: $color-canvas-17;
  color_card_theme_17: $color-card-17;
  color_dark_highlight_theme_17: $color-dark-highlight-17;
  color_light_highlight_theme_17: $color-light-highlight-17;
  color_text_primary_theme_17: $color-text-primary-17;
  color_text_secondary_theme_17: $color-text-secondary-17;
  color_text_tertiary_theme_17: $color-text-tertiary-17;
  color_button_disabled_theme_17: $color-button-disabled-17;
}
