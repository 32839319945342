@import '../../styles/main';

$top-inset: calc(
  64px + constant(safe-area-inset-top)
); //rem is not working inside calc scope
$top-inset: calc(64px + env(safe-area-inset-top));

.menu {
  padding-bottom: 5rem;
}

.menu-item-list-wrapper {
  .on-site-notice-banner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: $top-inset rem(20px) rem(16px);

    img {
      width: rem(24px);
      height: rem(24px);
      margin-right: rem(15px);
    }

    p {
      font-size: rem(14px);
      margin: 0;
    }
  }

  .offset-top {
    margin-top: $top-inset;
  }
}

.item-list {
  margin: rem(10px);
  padding: 0;

  li {
    list-style: none;

    &:nth-child(odd) {
      margin: rem(10px) 0;
    }

    .product-info-wrapper {
      display: flex;
      flex-direction: row;
      text-decoration: none;
    }

    .product-info {
      flex-grow: 1;
      padding: 0 rem(8px);
      width: 67%;
      box-sizing: border-box;

      .product-name {
        text-transform: uppercase;
        margin: rem(10px) 0 rem(8px) 0;
        height: rem(20px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .product-desc {
        margin: 0;
        font-size: rem(14px);
        height: rem(32px);
        max-height: rem(32px);

        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
      }

      .product-price {
        font-size: rem(12px);
        margin: rem(10px) 0 rem(10px);
        height: rem(10px);
      }
    }

    .product-image {
      flex-grow: 0;
      display: flex;
      align-items: center;

      &.hide {
        display: none;
      }

      img {
        display: block;
        width: rem(100px);
      }
    }
  }
}

.tax-discount {
  margin: rem(15px);
  white-space: pre-line;
  font-size: rem(12px);
  opacity: 0.5;
}
