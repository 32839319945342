@import '../../styles/main';

$breath-duration: 1.5s;
$breath-distance: 4px;
$breath-offset-1: 1s;
$breath-offset-2: 0.5s;

@-webkit-keyframes breath {
  0%,
  100% {
    -webkit-transform: translateY($breath-distance);
  }
  50% {
    -webkit-transform: translateY(-$breath-distance);
  }
}

@keyframes breath {
  0%,
  100% {
    transform: translateY($breath-distance);
  }
  50% {
    transform: translateY(-$breath-distance);
  }
}

.breath {
  -webkit-animation-name: breath;
  animation-name: breath;
  -webkit-animation-duration: $breath-duration;
  animation-duration: $breath-duration;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.breath:nth-of-type(3n + 1) {
  -webkit-animation-delay: $breath-offset-1;
  animation-delay: $breath-offset-1;
}

.breath:nth-of-type(3n + 2) {
  -webkit-animation-delay: $breath-offset-2;
  animation-delay: $breath-offset-2;
}

.loading-dots {
  text-align: center;
  margin-top: calc(
    32px + constant(safe-area-inset-top)
  ); //rem is not working inside calc scope
  margin-top: calc(32px + env(safe-area-inset-top));
  display: flex;
  justify-content: center;

  span {
    font-family: $native;
    margin: 0 0.25rem;
  }

  .loading,
  .ready {
    flex: none;
  }

  .loading {
    @extend .breath;
  }
}
