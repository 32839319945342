@import '../../styles/main';

.page-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 80px;
  width: 100%;

  &.fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 200;
    height: rem(80px);
    border-top: 1px solid #ccc;
  }

  button {
    width: 90%;
    height: 52px;
    padding: 0;
  }

  &.hide {
    display: none;
  }

  .add-to-order-text {
    font-size: rem(18px);
  }

  .button-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;

    &.center {
      justify-content: center;
    }

    .primary-text {
      font-size: rem(18px);
    }

    .secondary-text {
      font-family: $native;
      font-size: rem(16px);
      font-weight: normal;
    }
  }
}
