@import './variables';

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: rem(20px);
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    text-transform: uppercase;
    color: $text-color-gray;
    margin-bottom: rem(5px);
    font-weight: $heavy-weight;
    font-size: rem(13px);
    opacity: 0.5;
  }

  .error-label {
    display: none;
  }

  input {
    border: none;
    border-bottom: solid rem(1px) $form-border-color;
    outline: none;
    color: $text-color-gray;
    font-size: rem(17px);
  }

  .ic-lock {
    position: absolute;
    width: rem(23px);
    height: rem(23px);
    right: 0px;
    top: rem(13px);
    background-color: $fg-color;
  }

  .char-counter {
    display: flex;
    justify-content: flex-end;
    margin-top: rem(10px);
    font-size: rem(13px);
  }

  &.form-error {
    label {
      color: $form-error;
      font-size: rem(14px);
    }
    input {
      border-bottom: 1px solid $form-error;
    }
    .error-label {
      padding-top: 6px;
      display: block;
      color: $form-error;
      font-size: rem(12px);
    }
  }
}

.contains-forms {
  &.form-padding {
    padding: rem(16px);
  }
}
