/* Theme for Palette 15 */
/* Theme for Solitude Mountain Venue */

$color-primary-15: #173963;
$color-secondary-15: #173963;
$color-nav-15: #00afd0;
$color-accent-15: #00afd0;
$color-discount-15: #d87c28;
$color-error-15: #b11010;
$color-canvas-15: #ececec;
$color-card-15: #ffffff;
$color-dark-highlight-15: #b9babb;
$color-light-highlight-15: #cacbcc;
$color-text-primary-15: #322a2e;
$color-text-secondary-15: #ffffff;
$color-text-tertiary-15: #000000;

$color-button-disabled-15: lighten($color-accent-15, 40%);

.theme-palette-15 {
  .color-primary {
    color: $color-primary-15;
  }
  .bg-color-primary {
    background-color: $color-primary-15;
  }

  .color-secondary {
    color: $color-secondary-15;
  }
  .bg-color-secondary {
    background-color: $color-secondary-15;
  }
  .border-color-secondary {
    border-color: $color-secondary-15;
  }

  .toast-text-color {
    color: $color-primary-15;
  }

  .toast-bg-color {
    background-color: $color-discount-15;
  }

  .color-nav,
  a.color-nav:link,
  a.color-nav:visited,
  a.color-nav:hover {
    color: $color-nav-15;
  }

  .color-nav-header,
  a.color-nav-header:link,
  a.color-nav-header:visited,
  a.color-nav-header:hover {
    color: $color-nav-15;
  }

  .color-checkmark {
    color: $color-primary-15;
  }

  .color-confirmation-icon {
    color: $color-light-highlight-15;
  }

  .color-confirmation-text {
    color: $color-text-primary-15;
  }

  .bg-color-nav {
    background-color: $color-nav-15;
  }
  .border-color-nav {
    border-color: $color-nav-15;
  }

  .color-accent {
    color: $color-accent-15;
  }
  .bg-color-accent,
  .bg-after-color-accent:after {
    background-color: $color-accent-15;
  }
  .border-color-accent {
    border-color: $color-accent-15;
  }
  .border-color-top-accent {
    border-top-color: $color-accent-15;
  }

  .color-discount {
    color: $color-discount-15;
  }
  .bg-color-discount {
    background-color: $color-discount-15;
  }

  .color-error {
    color: $color-error-15;
  }
  .bg-color-error {
    background-color: $color-error-15;
  }

  .color-canvas {
    color: $color-canvas-15;
  }
  .bg-color-canvas {
    background-color: $color-canvas-15;
  }

  .color-card {
    color: $color-card-15;
  }
  .bg-color-card,
  .content {
    background-color: $color-card-15;
  }

  .color-dark-highlight {
    color: $color-dark-highlight-15;
  }
  .bg-color-dark-highlight {
    background-color: $color-dark-highlight-15;
  }
  .border-color-dark-highlight {
    border-color: $color-dark-highlight-15;
  }

  .color-light-highlight {
    color: $color-light-highlight-15;
  }
  .bg-color-light-highlight {
    background-color: $color-light-highlight-15;
  }

  .color-text-primary {
    color: $color-text-primary-15;
  }

  .color-text-secondary {
    color: $color-text-secondary-15;
  }

  .color-text-tertiary,
  .title {
    color: $color-text-tertiary-15;
  }

  .color-disabled {
    color: $color-dark-highlight-15;
  }

  // Specific form elements
  button {
    color: $color-card-15;
    background-color: $color-accent-15;
  }
}

:export {
  color_primary_theme_15: $color-primary-15;
  color_secondary_theme_15: $color-secondary-15;
  color_nav_theme_15: $color-nav-15;
  color_accent_theme_15: $color-accent-15;
  color_discount_theme_15: $color-discount-15;
  color_error_theme_15: $color-error-15;
  color_canvas_theme_15: $color-canvas-15;
  color_card_theme_15: $color-card-15;
  color_dark_highlight_theme_15: $color-dark-highlight-15;
  color_light_highlight_theme_15: $color-light-highlight-15;
  color_text_primary_theme_15: $color-text-primary-15;
  color_text_secondary_theme_15: $color-text-secondary-15;
  color_text_tertiary_theme_15: $color-text-tertiary-15;
  color_button_disabled_theme_15: $color-button-disabled-15;
}
