@import '../../styles/main';

.menu-container {
  position: fixed;
  min-height: rem(40px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-x: auto;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.25);
  margin-top: calc(64px + constant(safe-area-inset-top));
  margin-top: calc(64px + env(safe-area-inset-top));
  z-index: 10;

  .menu {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    text-transform: capitalize;
    overflow-y: hidden;
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
      height: 0;
      -webkit-appearance: none;
    }

    li {
      margin-right: rem(40px);
      padding-top: rem(16px);
      padding-bottom: rem(16px);

      &:first-child {
        a {
          margin-left: rem(24px);
        }
      }

      &:last-child {
        a {
          margin-right: rem(24px);
        }
      }

      &.active {
        font-weight: $bold-weight;
      }

      a {
        text-transform: uppercase;
        white-space: nowrap;

        &:link,
        &:visited {
          text-decoration: none;
        }

        &:hover {
          font-weight: bold;
        }
      }
    }
  }
}
