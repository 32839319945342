@import '../../styles/main';

.discount-list-wrapper {
  font-size: rem(16px);
  font-family: $native;

  .wrapper-title {
    .title {
      opacity: 0.5;
    }
  }

  .discount-content {
    padding: rem(17px) rem(15px) rem(17px) rem(48px);
    line-height: rem(22px);

    .discount-item-title {
      font-weight: bold;
    }
    .season-pass-auto-text {
      color: #008577;
    }
  }

  .content-border {
    border-top: solid #ccc rem(1px);
    border-bottom: solid #ccc rem(1px);
  }

  .discount-content > * {
    padding: rem(4px);
  }

  .modifier-container .modifier-wrapper .modifier-text {
    font-size: rem(16px);
    font-weight: 400;
  }

  .content {
    position: relative;
    .apply-button {
      position: absolute;
      width: rem(30px);
      top: rem(36px);
      right: rem(38px);
      font-size: rem(16px);
      text-transform: uppercase;
      cursor: pointer;
    }
    .form-group {
      margin-bottom: 1.25rem;
    }
  }
  .button-wrapper {
    padding-bottom: rem(20px);
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      flex-shrink: 0;
      width: 90%;
      height: rem(50px);
      padding: 0;
      cursor: pointer;
    }
  }
}
