/* Theme for Palette 6 */

$color-primary-6: #222222;
$color-secondary-6: #0d62a7;
$color-nav-6: #fba305;
$color-accent-6: #0283bf;
$color-discount-6: #fba305;
$color-error-6: #bc4146;
$color-canvas-6: #f2f2f2;
$color-card-6: #fafafa;
$color-dark-highlight-6: #cccccc;
$color-light-highlight-6: #d6d6d6;
$color-text-primary-6: #222222;
$color-text-secondary-6: #fafafa;
$color-text-tertiary-6: #222222;

$color-button-disabled-6: lighten($color-accent-6, 40%);

.theme-palette-6 {
  .color-primary {
    color: $color-primary-6;
  }
  .bg-color-primary {
    background-color: $color-primary-6;
  }

  .color-secondary {
    color: $color-secondary-6;
  }
  .bg-color-secondary {
    background-color: $color-secondary-6;
  }
  .border-color-secondary {
    border-color: $color-secondary-6;
  }

  .toast-text-color {
    color: $color-primary-6;
  }

  .toast-bg-color {
    background-color: $color-discount-6;
  }

  .color-nav,
  a.color-nav:link,
  a.color-nav:visited,
  a.color-nav:hover {
    color: $color-nav-6;
  }

  .color-nav-header,
  a.color-nav-header:link,
  a.color-nav-header:visited,
  a.color-nav-header:hover {
    color: $color-nav-6;
  }

  .color-checkmark {
    color: $color-primary-6;
  }

  .color-confirmation-icon {
    color: $color-light-highlight-6;
  }

  .color-confirmation-text {
    color: $color-text-primary-6;
  }

  .bg-color-nav {
    background-color: $color-nav-6;
  }
  .border-color-nav {
    border-color: $color-nav-6;
  }

  .color-accent {
    color: $color-accent-6;
  }
  .bg-color-accent,
  .bg-after-color-accent:after {
    background-color: $color-accent-6;
  }
  .border-color-accent {
    border-color: $color-accent-6;
  }
  .border-color-top-accent {
    border-top-color: $color-accent-6;
  }

  .color-discount {
    color: $color-discount-6;
  }
  .bg-color-discount {
    background-color: $color-discount-6;
  }

  .color-error {
    color: $color-error-6;
  }
  .bg-color-error {
    background-color: $color-error-6;
  }

  .color-canvas {
    color: $color-canvas-6;
  }
  .bg-color-canvas {
    background-color: $color-canvas-6;
  }

  .color-card {
    color: $color-card-6;
  }
  .bg-color-card,
  .content {
    background-color: $color-card-6;
  }

  .color-dark-highlight {
    color: $color-dark-highlight-6;
  }
  .bg-color-dark-highlight {
    background-color: $color-dark-highlight-6;
  }
  .border-color-dark-highlight {
    border-color: $color-dark-highlight-6;
  }

  .color-light-highlight {
    color: $color-light-highlight-6;
  }
  .bg-color-light-highlight {
    background-color: $color-light-highlight-6;
  }

  .color-text-primary {
    color: $color-text-primary-6;
  }

  .color-text-secondary {
    color: $color-text-secondary-6;
  }

  .color-text-tertiary,
  .title {
    color: $color-text-tertiary-6;
  }

  .color-disabled {
    color: $color-dark-highlight-6;
  }

  // Specific form elements
  button {
    color: $color-card-6;
    background-color: $color-accent-6;
  }
}

:export {
  color_primary_theme_6: $color-primary-6;
  color_secondary_theme_6: $color-secondary-6;
  color_nav_theme_6: $color-nav-6;
  color_accent_theme_6: $color-accent-6;
  color_discount_theme_6: $color-discount-6;
  color_error_theme_6: $color-error-6;
  color_canvas_theme_6: $color-canvas-6;
  color_card_theme_6: $color-card-6;
  color_dark_highlight_theme_6: $color-dark-highlight-6;
  color_light_highlight_theme_6: $color-light-highlight-6;
  color_text_primary_theme_6: $color-text-primary-6;
  color_text_secondary_theme_6: $color-text-secondary-6;
  color_text_tertiary_theme_6: $color-text-tertiary-6;
  color_button_disabled_theme_6: $color-button-disabled-6;
}
