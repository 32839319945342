/* Pastel colors for testing */

.theme-pastel {
  $color-primary-pastel: #a09bcc;
  $color-secondary-pastel: #caa7bd;
  $color-nav-pastel: #fddadf;
  $color-accent-pastel: #ffb9c4;
  $color-discount-pastel: #ffd3d4;
  $color-error-pastel: #f6d6de;
  $color-canvas-pastel: #fcebf6;
  $color-card-pastel: #fcebf6;
  $color-dark-highlight-pastel: #cccccc;
  $color-light-highlight-pastel: #d6d6d6;
  $color-text-primary-pastel: #a09bcc;
  $color-text-secondary-pastel: #ece3fc;
  $color-text-tertiary-pastel: #cba6cc;

  $color-button-disabled-pastel: lighten($color-accent-pastel, 40%);

  .color-primary {
    color: $color-primary-pastel;
  }
  .bg-color-primary {
    background-color: $color-primary-pastel;
  }

  .color-secondary {
    color: $color-secondary-pastel;
  }
  .bg-color-secondary {
    background-color: $color-secondary-pastel;
  }
  .border-color-secondary {
    border-color: $color-secondary-pastel;
  }

  .toast-text-color {
    color: $color-primary-pastel;
  }

  .toast-bg-color {
    color: $color-discount-pastel;
  }

  .color-nav,
  a.color-nav:link,
  a.color-nav:visited,
  a.color-nav:hover {
    color: $color-nav-pastel;
  }
  .bg-color-nav {
    background-color: $color-nav-pastel;
  }
  .border-color-nav {
    border-color: $color-nav-pastel;
  }

  .color-accent {
    color: $color-accent-pastel;
  }
  .bg-color-accent,
  .bg-after-color-accent:after {
    background-color: $color-accent-pastel;
  }
  .border-color-accent {
    border-color: $color-accent-pastel;
  }
  .border-color-top-accent {
    border-top-color: $color-accent-pastel;
  }

  .color-discount {
    color: $color-discount-pastel;
  }
  .bg-color-discount {
    background-color: $color-discount-pastel;
  }

  .color-error {
    color: $color-error-pastel;
  }
  .bg-color-error {
    background-color: $color-error-pastel;
  }

  .color-canvas {
    color: $color-canvas-pastel;
  }
  .bg-color-canvas {
    background-color: $color-canvas-pastel;
  }

  .color-card {
    color: $color-card-pastel;
  }
  .bg-color-card,
  .content {
    background-color: $color-card-pastel;
  }

  .color-dark-highlight {
    color: $color-dark-highlight-pastel;
  }
  .bg-color-dark-highlight {
    background-color: $color-dark-highlight-pastel;
  }
  .border-color-dark-highlight {
    border-color: $color-dark-highlight-pastel;
  }

  .color-light-highlight {
    color: $color-light-highlight-pastel;
  }
  .bg-color-light-highlight {
    background-color: $color-light-highlight-pastel;
  }

  .color-text-primary,
  .title {
    color: $color-text-primary-pastel;
  }

  .color-text-secondary {
    color: $color-text-secondary-pastel;
  }

  .color-text-tertiary {
    color: $color-text-tertiary-pastel;
  }

  .color-disabled {
    color: $color-dark-highlight-pastel;
  }

  // Specific form elements
  button {
    color: $color-card-pastel;
    background-color: $color-accent-pastel;
  }
}
