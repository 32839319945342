/* Theme for Palette 2 */

$color-primary-2: #006747;
$color-secondary-2: #006747;
$color-nav-2: #bfa96b;
$color-accent-2: #a3842c;
$color-discount-2: #a3842c;
$color-error-2: #c3432a;
$color-canvas-2: #f1efec;
$color-card-2: #ffffff;
$color-dark-highlight-2: #b9babb;
$color-light-highlight-2: #cacbcc;
$color-text-primary-2: #000000;
$color-text-secondary-2: #ffffff;
$color-text-tertiary-2: #000000;

$color-button-disabled-2: lighten($color-accent-2, 40%);

.theme-palette-2 {
  .color-primary {
    color: $color-primary-2;
  }
  .bg-color-primary {
    background-color: $color-primary-2;
  }

  .color-secondary {
    color: $color-secondary-2;
  }
  .bg-color-secondary {
    background-color: $color-secondary-2;
  }
  .border-color-secondary {
    border-color: $color-secondary-2;
  }

  .toast-text-color {
    color: $color-primary-2;
  }

  .toast-bg-color {
    background-color: $color-discount-2;
  }

  .color-nav,
  a.color-nav:link,
  a.color-nav:visited,
  a.color-nav:hover {
    color: $color-nav-2;
  }

  .color-nav-header,
  a.color-nav-header:link,
  a.color-nav-header:visited,
  a.color-nav-header:hover {
    color: $color-nav-2;
  }

  .color-checkmark {
    color: $color-primary-2;
  }

  .color-confirmation-icon {
    color: $color-light-highlight-2;
  }

  .color-confirmation-text {
    color: $color-text-primary-2;
  }

  .bg-color-nav {
    background-color: $color-nav-2;
  }
  .border-color-nav {
    border-color: $color-nav-2;
  }

  .color-accent {
    color: $color-accent-2;
  }
  .bg-color-accent,
  .bg-after-color-accent:after {
    background-color: $color-accent-2;
  }
  .border-color-accent {
    border-color: $color-accent-2;
  }
  .border-color-top-accent {
    border-top-color: $color-accent-2;
  }

  .color-discount {
    color: $color-discount-2;
  }
  .bg-color-discount {
    background-color: $color-discount-2;
  }

  .color-error {
    color: $color-error-2;
  }
  .bg-color-error {
    background-color: $color-error-2;
  }

  .color-canvas {
    color: $color-canvas-2;
  }
  .bg-color-canvas {
    background-color: $color-canvas-2;
  }

  .color-card {
    color: $color-card-2;
  }
  .bg-color-card,
  .content {
    background-color: $color-card-2;
  }

  .color-dark-highlight {
    color: $color-dark-highlight-2;
  }
  .bg-color-dark-highlight {
    background-color: $color-dark-highlight-2;
  }
  .border-color-dark-highlight {
    border-color: $color-dark-highlight-2;
  }

  .color-light-highlight {
    color: $color-light-highlight-2;
  }
  .bg-color-light-highlight {
    background-color: $color-light-highlight-2;
  }

  .color-text-primary {
    color: $color-text-primary-2;
  }

  .color-text-secondary {
    color: $color-text-secondary-2;
  }

  .color-text-tertiary,
  .title {
    color: $color-text-tertiary-2;
  }

  .color-disabled {
    color: $color-dark-highlight-2;
  }

  // Specific form elements
  button {
    color: $color-card-2;
    background-color: $color-accent-2;
  }
}

:export {
  color_primary_theme_2: $color-primary-2;
  color_secondary_theme_2: $color-secondary-2;
  color_nav_theme_2: $color-nav-2;
  color_accent_theme_2: $color-accent-2;
  color_discount_theme_2: $color-discount-2;
  color_error_theme_2: $color-error-2;
  color_canvas_theme_2: $color-canvas-2;
  color_card_theme_2: $color-card-2;
  color_dark_highlight_theme_2: $color-dark-highlight-2;
  color_light_highlight_theme_2: $color-light-highlight-2;
  color_text_primary_theme_2: $color-text-primary-2;
  color_text_secondary_theme_2: $color-text-secondary-2;
  color_text_tertiary_theme_2: $color-text-tertiary-2;
  color_button_disabled_theme_2: $color-button-disabled-2;
}
