@import '../../styles/main';

.card {
  font-family: $native;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  margin: rem(16px);
  padding: rem(16px);

  .card-title {
    font-weight: $heavy-weight;
  }

  .card-message {
    font-size: rem(13px);
    line-height: 1.23;
    margin-top: rem(4px);
  }

  .card-cta {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: rem(12px);
    cursor: pointer;

    .card-cta-label {
      text-transform: uppercase;
      font-weight: $heavy-weight;
    }

    .card-cta-chevron-right {
      box-sizing: border-box;
      position: relative;
      display: block;
      transform: scale(var(--ggs, 1));
      width: rem(24px);
      height: rem(24px);
      border: 2px solid transparent;
      border-radius: rem(100px);

      &::after {
        content: '';
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: rem(14px);
        height: rem(14px);
        border-bottom: 1px solid;
        border-right: 1px solid;
        transform: rotate(-45deg);
        right: rem(6px);
        top: rem(4px);
      }
    }
  }
}
