@import '../../styles/main.scss';

.customer-support-wrapper {
  .icon-small {
    font-size: rem(24px);
    padding-right: rem(10px);
    vertical-align: middle;
  }
  .customer-support {
    display: flex;
    align-items: center;
    margin: rem(16px);

    .phone-nav {
      text-decoration: none;
    }
  }
}
