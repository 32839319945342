#accesso-pay {
  text-align: center;
}

#accesso-pay iframe {
  position: relative;
  margin: 0 auto;
  border: 0;
  width: 100%;
  height: calc(99vh - 64px);
}
