@import '../../styles/main';

.menu.error {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .not-available {
    text-align: center;

    .msg {
      margin-top: 10px;
    }

    .menus-link {
      margin-top: 26px;

      a {
        font-weight: $heavy-weight;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }
}
