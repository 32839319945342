@import '../../styles/main';

.cart-wrapper {
  .title {
    opacity: 0.5;
  }

  li {
    border-bottom: solid #e4e4e4 rem(1px);
    list-style-type: none;
    position: relative;

    .order-item-swipe-component {
      position: relative;

      .swipe-component {
        position: relative;
        width: 100%;
        z-index: 10;

        .remove-button {
          background-color: $color-error;
        }

        &.reset > .react-swipeable-view-container {
          transition: transform 0.3s !important;
          transform: translateX(0%) !important;
        }
      }

      .order-item-info {
        display: flex;
        position: relative;
        padding: rem(12px) 0 rem(4px) rem(16px);
        z-index: 10;

        .product-image {
          margin-right: rem(16px);

          .image {
            width: rem(48px);
            height: rem(48px);
            border-radius: 10%;
          }
        }

        .product {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-right: rem(16px);

          &-details-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: rem(10px);
          }

          .discount-price-color {
            color: $color-discount;
          }

          &-modifiers-wrapper {
            .selected-modifier-wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: rem(10px);

              .modifier-display-name {
                font-size: rem(13px);
              }

              .display-price {
                font-size: rem(13px);
                .add-sign {
                  vertical-align: middle;
                }
                .calculated-modifier-price {
                  vertical-align: middle;
                }
              }
            }
          }

          .combo,
          .quantity {
            font-size: rem(13px);
          }

          .discount-label {
            margin-top: rem(10px);
            font-size: rem(13px);
            color: $color-discount;
          }

          .age-restriction {
            margin-top: rem(10px);
            font-size: rem(13px);
            color: $color-error;
          }
        }
      }

      .order-item-control {
        display: flex;
        justify-content: flex-end;
        gap: rem(40px);
        padding: 0 rem(16px) rem(4px) 0;
        .order-item-button {
          text-decoration: underline;
          text-transform: capitalize;
          color: $text-color-blue;
          height: rem(40px);
          border: none;
          cursor: pointer;
          font-size: rem(13px);
          background-color: transparent;
          padding: 0;
        }
      }

      .prices {
        font-size: rem(16px);
        display: flex;
        flex-direction: column;
        line-height: rem(30px);
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      position: absolute;
      height: 100%;
      width: 100%;

      & button {
        width: 50%;
      }
    }
  }

  .order-row {
    display: flex;
    justify-content: space-between;
    margin: 0 rem(15px);
    padding: rem(8px) 0;
    font-size: rem(16px);

    .discount-close-icon {
      padding-top: rem(3px);
    }
  }

  .order-row-bold {
    font-weight: 600;
  }

  .discount-row {
    svg {
      padding-right: 5px;
      vertical-align: middle;
    }
  }

  .tax-sales-pass-text {
    margin: rem(7px) rem(15px) rem(15px);
    padding-top: rem(15px);
    white-space: pre-line;
    font-size: rem(13px);
    opacity: 0.5;
    border-top: 1px solid #00000026;
  }

  .button-container {
    margin-top: rem(20px);
  }
}

.content-wrapper {
  .empty-cart-wrapper {
    height: 100%;

    .cart-content {
      height: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .empty-description {
      font-weight: $regular-weight;
      font-size: rem(16px);
      padding: rem(26px);
    }
  }
}
