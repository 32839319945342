@import '../../styles/main';

.places-list-container {
  .select-menu {
    font-size: rem(18px);
    font-weight: bold;
    padding: 20px;
    opacity: 0.5;
  }

  .on-site-notice {
    font-size: rem(14px);
    padding: 20px;
    text-align: center;
  }

  .places-list > :first-child {
    border-top: 1px solid gray;
  }
}
