@import '../../styles/main';

.payment-form {
  .submit-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 80px;
    width: 100%;
    margin-top: 20px;

    button {
      width: 90%;
      height: 52px;
      padding: 0;
    }

    .button-content-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 85%;

      &.center {
        justify-content: center;
      }

      .primary-text {
        font-size: rem(18px);
      }

      .secondary-text {
        font-family: $native;
        font-size: rem(16px);
        font-weight: normal;
      }
    }
  }
}
