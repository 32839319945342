@import '../../../styles/main.scss';

.order-checkin-status-wrapper {
  .checkin-order-actions {
    display: flex;
    flex-direction: column;

    img {
      width: rem(24px);
      height: rem(24px);
      margin-right: rem(15px);
    }

    p {
      margin: 0;
      line-height: rem(25px);
    }

    .checkin-message {
      display: flex;
      align-items: center;
      margin: rem(16px);
    }

    .checkin-order-button-wrapper {
      height: 52px;
      padding: 0 20px;

      button {
        flex-shrink: 0;
        width: 100%;
        height: 50px;
        padding: 0;
        cursor: pointer;
      }
    }
  }
}
