/* Theme for Palette 5 */

$color-primary-5: #0283bf;
$color-secondary-5: #0d62a7;
$color-nav-5: #5bbeff;
$color-accent-5: #fba305;
$color-discount-5: #fba305;
$color-error-5: #bc4146;
$color-canvas-5: #f2f2f2;
$color-card-5: #fafafa;
$color-dark-highlight-5: #cccccc;
$color-light-highlight-5: #d6d6d6;
$color-text-primary-5: #222222;
$color-text-secondary-5: #fafafa;
$color-text-tertiary-5: #222222;

$color-button-disabled-5: lighten($color-accent-5, 40%);

.theme-palette-5 {
  .color-primary {
    color: $color-primary-5;
  }
  .bg-color-primary {
    background-color: $color-primary-5;
  }
  .border-color-secondary {
    border-color: $color-secondary-5;
  }

  .color-secondary {
    color: $color-secondary-5;
  }
  .bg-color-secondary {
    background-color: $color-secondary-5;
  }

  .toast-text-color {
    color: $color-primary-5;
  }

  .toast-bg-color {
    background-color: $color-discount-5;
  }

  .color-nav,
  a.color-nav:link,
  a.color-nav:visited,
  a.color-nav:hover {
    color: $color-nav-5;
  }

  .color-nav-header,
  a.color-nav-header:link,
  a.color-nav-header:visited,
  a.color-nav-header:hover {
    color: $color-nav-5;
  }

  .color-checkmark {
    color: $color-primary-5;
  }

  .color-confirmation-icon {
    color: $color-light-highlight-5;
  }

  .color-confirmation-text {
    color: $color-text-primary-5;
  }

  .bg-color-nav {
    background-color: $color-nav-5;
  }
  .border-color-nav {
    border-color: $color-nav-5;
  }

  .color-accent {
    color: $color-accent-5;
  }
  .bg-color-accent,
  .bg-after-color-accent:after {
    background-color: $color-accent-5;
  }
  .border-color-accent {
    border-color: $color-accent-5;
  }
  .border-color-top-accent {
    border-top-color: $color-accent-5;
  }

  .color-discount {
    color: $color-discount-5;
  }
  .bg-color-discount {
    background-color: $color-discount-5;
  }

  .color-error {
    color: $color-error-5;
  }
  .bg-color-error {
    background-color: $color-error-5;
  }

  .color-canvas {
    color: $color-canvas-5;
  }
  .bg-color-canvas {
    background-color: $color-canvas-5;
  }

  .color-card {
    color: $color-card-5;
  }
  .bg-color-card,
  .content {
    background-color: $color-card-5;
  }

  .color-dark-highlight {
    color: $color-dark-highlight-5;
  }
  .bg-color-dark-highlight {
    background-color: $color-dark-highlight-5;
  }
  .border-color-dark-highlight {
    border-color: $color-dark-highlight-5;
  }

  .color-light-highlight {
    color: $color-light-highlight-5;
  }
  .bg-color-light-highlight {
    background-color: $color-light-highlight-5;
  }

  .color-text-primary {
    color: $color-text-primary-5;
  }

  .color-text-secondary {
    color: $color-text-secondary-5;
  }

  .color-text-tertiary,
  .title {
    color: $color-text-tertiary-5;
  }

  .color-disabled {
    color: $color-dark-highlight-5;
  }

  // Specific form elements
  button {
    color: $color-card-5;
    background-color: $color-accent-5;
  }
}

:export {
  color_primary_theme_5: $color-primary-5;
  color_secondary_theme_5: $color-secondary-5;
  color_nav_theme_5: $color-nav-5;
  color_accent_theme_5: $color-accent-5;
  color_discount_theme_5: $color-discount-5;
  color_error_theme_5: $color-error-5;
  color_canvas_theme_5: $color-canvas-5;
  color_card_theme_5: $color-card-5;
  color_dark_highlight_theme_5: $color-dark-highlight-5;
  color_light_highlight_theme_5: $color-light-highlight-5;
  color_text_primary_theme_5: $color-text-primary-5;
  color_text_secondary_theme_5: $color-text-secondary-5;
  color_text_tertiary_theme_5: $color-text-tertiary-5;
  color_button_disabled_theme_5: $color-button-disabled-5;
}
