/* Theme for Palette 13 */
/* Theme for Bear Mountain Venue */

$color-primary-13: #000000;
$color-secondary-13: #58595b;
$color-nav-13: #c12035;
$color-accent-13: #c12035;
$color-discount-13: #098934;
$color-error-13: #980014;
$color-canvas-13: #f1efec;
$color-card-13: #ffffff;
$color-dark-highlight-13: #b9babb;
$color-light-highlight-13: #cacbcc;
$color-text-primary-13: #000000;
$color-text-secondary-13: #ffffff;
$color-text-tertiary-13: #000000;

$color-button-disabled-13: lighten($color-accent-13, 40%);

.theme-palette-13 {
  .color-primary {
    color: $color-primary-13;
  }
  .bg-color-primary {
    background-color: $color-primary-13;
  }

  .color-secondary {
    color: $color-secondary-13;
  }
  .bg-color-secondary {
    background-color: $color-secondary-13;
  }
  .border-color-secondary {
    border-color: $color-secondary-13;
  }

  .toast-text-color {
    color: $color-primary-13;
  }

  .toast-bg-color {
    background-color: $color-discount-13;
  }

  .color-nav,
  a.color-nav:link,
  a.color-nav:visited,
  a.color-nav:hover {
    color: $color-nav-13;
  }

  .color-nav-header,
  a.color-nav-header:link,
  a.color-nav-header:visited,
  a.color-nav-header:hover {
    color: $color-nav-13;
  }

  .color-checkmark {
    color: $color-primary-13;
  }

  .color-confirmation-icon {
    color: $color-light-highlight-13;
  }

  .color-confirmation-text {
    color: $color-text-primary-13;
  }

  .bg-color-nav {
    background-color: $color-nav-13;
  }
  .border-color-nav {
    border-color: $color-nav-13;
  }

  .color-accent {
    color: $color-accent-13;
  }
  .bg-color-accent,
  .bg-after-color-accent:after {
    background-color: $color-accent-13;
  }
  .border-color-accent {
    border-color: $color-accent-13;
  }
  .border-color-top-accent {
    border-top-color: $color-accent-13;
  }

  .color-discount {
    color: $color-discount-13;
  }
  .bg-color-discount {
    background-color: $color-discount-13;
  }

  .color-error {
    color: $color-error-13;
  }
  .bg-color-error {
    background-color: $color-error-13;
  }

  .color-canvas {
    color: $color-canvas-13;
  }
  .bg-color-canvas {
    background-color: $color-canvas-13;
  }

  .color-card {
    color: $color-card-13;
  }
  .bg-color-card,
  .content {
    background-color: $color-card-13;
  }

  .color-dark-highlight {
    color: $color-dark-highlight-13;
  }
  .bg-color-dark-highlight {
    background-color: $color-dark-highlight-13;
  }
  .border-color-dark-highlight {
    border-color: $color-dark-highlight-13;
  }

  .color-light-highlight {
    color: $color-light-highlight-13;
  }
  .bg-color-light-highlight {
    background-color: $color-light-highlight-13;
  }

  .color-text-primary {
    color: $color-text-primary-13;
  }

  .color-text-secondary {
    color: $color-text-secondary-13;
  }

  .color-text-tertiary,
  .title {
    color: $color-text-tertiary-13;
  }

  .color-disabled {
    color: $color-dark-highlight-13;
  }

  // Specific form elements
  button {
    color: $color-card-13;
    background-color: $color-accent-13;
  }
}

:export {
  color_primary_theme_13: $color-primary-13;
  color_secondary_theme_13: $color-secondary-13;
  color_nav_theme_13: $color-nav-13;
  color_accent_theme_13: $color-accent-13;
  color_discount_theme_13: $color-discount-13;
  color_error_theme_13: $color-error-13;
  color_canvas_theme_13: $color-canvas-13;
  color_card_theme_13: $color-card-13;
  color_dark_highlight_theme_13: $color-dark-highlight-13;
  color_light_highlight_theme_13: $color-light-highlight-13;
  color_text_primary_theme_13: $color-text-primary-13;
  color_text_secondary_theme_13: $color-text-secondary-13;
  color_text_tertiary_theme_13: $color-text-tertiary-13;
  color_button_disabled_theme_13: $color-button-disabled-13;
}
