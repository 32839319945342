/* Theme for Palette 10 */

$color-primary-10: #000000;
$color-secondary-10: #000000;
$color-nav-10: #800600;
$color-accent-10: #ff0d00;
$color-discount-10: #01aef0;
$color-error-10: #f40b00;
$color-canvas-10: #f1efec;
$color-card-10: #ffffff;
$color-dark-highlight-10: #b9babb;
$color-light-highlight-10: #cacbcc;
$color-text-primary-10: #000000;
$color-text-secondary-10: #ffffff;
$color-text-tertiary-10: #000000;

$color-button-disabled-10: lighten($color-accent-10, 40%);

.theme-palette-10 {
  .color-primary {
    color: $color-primary-10;
  }
  .bg-color-primary {
    background-color: $color-primary-10;
  }

  .color-secondary {
    color: $color-secondary-10;
  }
  .bg-color-secondary {
    background-color: $color-secondary-10;
  }
  .border-color-secondary {
    border-color: $color-secondary-10;
  }

  .toast-text-color {
    color: $color-primary-10;
  }

  .toast-bg-color {
    background-color: $color-discount-10;
  }

  .color-nav,
  a.color-nav:link,
  a.color-nav:visited,
  a.color-nav:hover {
    color: $color-nav-10;
  }

  .color-nav-header,
  a.color-nav-header:link,
  a.color-nav-header:visited,
  a.color-nav-header:hover {
    color: $color-text-secondary-10;
  }

  .color-checkmark {
    color: $color-primary-10;
  }

  .color-confirmation-icon {
    color: $color-light-highlight-10;
  }

  .color-confirmation-text {
    color: $color-text-primary-10;
  }

  .bg-color-nav {
    background-color: $color-nav-10;
  }
  .border-color-nav {
    border-color: $color-nav-10;
  }

  .color-accent {
    color: $color-accent-10;
  }
  .bg-color-accent,
  .bg-after-color-accent:after {
    background-color: $color-accent-10;
  }
  .border-color-accent {
    border-color: $color-accent-10;
  }
  .border-color-top-accent {
    border-top-color: $color-accent-10;
  }

  .color-discount {
    color: $color-discount-10;
  }
  .bg-color-discount {
    background-color: $color-discount-10;
  }

  .color-error {
    color: $color-error-10;
  }
  .bg-color-error {
    background-color: $color-error-10;
  }

  .color-canvas {
    color: $color-canvas-10;
  }
  .bg-color-canvas {
    background-color: $color-canvas-10;
  }

  .color-card {
    color: $color-card-10;
  }
  .bg-color-card,
  .content {
    background-color: $color-card-10;
  }

  .color-dark-highlight {
    color: $color-dark-highlight-10;
  }
  .bg-color-dark-highlight {
    background-color: $color-dark-highlight-10;
  }
  .border-color-dark-highlight {
    border-color: $color-dark-highlight-10;
  }

  .color-light-highlight {
    color: $color-light-highlight-10;
  }
  .bg-color-light-highlight {
    background-color: $color-light-highlight-10;
  }

  .color-text-primary {
    color: $color-text-primary-10;
  }

  .color-text-secondary {
    color: $color-text-secondary-10;
  }

  .color-text-tertiary,
  .title {
    color: $color-text-tertiary-10;
  }

  .color-disabled {
    color: $color-dark-highlight-10;
  }

  // Specific form elements
  button {
    color: $color-card-10;
    background-color: $color-accent-10;
  }
}

:export {
  color_primary_theme_10: $color-primary-10;
  color_secondary_theme_10: $color-secondary-10;
  color_nav_theme_10: $color-nav-10;
  color_accent_theme_10: $color-accent-10;
  color_discount_theme_10: $color-discount-10;
  color_error_theme_10: $color-error-10;
  color_canvas_theme_10: $color-canvas-10;
  color_card_theme_10: $color-card-10;
  color_dark_highlight_theme_10: $color-dark-highlight-10;
  color_light_highlight_theme_10: $color-light-highlight-10;
  color_text_primary_theme_10: $color-text-primary-10;
  color_text_secondary_theme_10: $color-text-secondary-10;
  color_text_tertiary_theme_10: $color-text-tertiary-10;
  color_button_disabled_theme_10: $color-button-disabled-10;
}
