@import '../../styles/main';

#billing-info {
  .title {
    opacity: 0.5;
  }

  .ptw-picker {
    border: 1px solid #ccc;
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  [class^='arrow-'] {
    width: 0;
    height: 0;
    display: inline-block;
    vertical-align: middle;
    border-style: solid;
  }

  $arrow-size: 5px;
  $arrow-color: #999;

  .arrow-down {
    border-width: $arrow-size $arrow-size 0 $arrow-size;
    border-color: $arrow-color transparent transparent transparent;
  }

  .pickup-padding {
    padding-top: rem(16px);
  }

  .pickup-text {
    font-size: rem(13px);
  }
}
