@import '../../styles/main';

button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: none;
  font-size: rem(18px);
}
