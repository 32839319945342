@font-face {
  font-family: 'web-fnb';
  src: url('fonts/web-fnb.eot?3zxnbx');
  src: url('fonts/web-fnb.eot?3zxnbx#iefix') format('embedded-opentype'),
    url('fonts/web-fnb.ttf?3zxnbx') format('truetype'),
    url('fonts/web-fnb.woff?3zxnbx') format('woff'),
    url('fonts/web-fnb.svg?3zxnbx#web-fnb') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'web-fnb' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-i-alert:before {
  content: '\e900';
}
.icon-i-check-circle:before {
  content: '\e901';
}
.icon-i-help:before {
  content: '\e902';
}
.icon-i-maps:before {
  content: '\e903';
}
.icon-i-lock:before {
  content: '\e904';
}
.icon-i-discount:before {
  content: '\e905';
}
.icon-i-clock:before {
  content: '\e906';
}
.icon-i-chevron-back:before {
  content: '\e907';
}
.icon-i-close-x:before {
  content: '\e908';
}
.icon-i-minus:before {
  content: '\e909';
}
.icon-i-plus:before {
  content: '\e90a';
}
.icon-i-fire-pit:before {
  content: '\e90b';
}
