@import '../../styles/main';

.error-page-wrapper,
.error-message-wrapper,
.error-text-wrapper,
.error-header-wrapper,
.error-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.error-page-wrapper {
  height: 90%;

  .error-image-wrapper {
  }

  .error-message-wrapper {
    width: 100%;
    margin: rem(32px) 0;

    .error-header-wrapper {
      font-weight: bold;
      font-size: rem(20px);
      font-weight: $bold-weight;
    }

    .error-text-wrapper {
      max-width: 80%;
      text-align: center;
      font-size: rem(16px);

      p {
        line-height: rem(22px);
      }
    }
  }

  .error-button-wrapper {
    width: 100%;

    .error-button {
      width: 90%;
      color: #fff;
      font-weight: bold;
      padding-top: rem(16px);
      padding-bottom: rem(16px);
      font-size: rem(18px);
      font-weight: $bold-weight;
    }
  }
}
