@import '../../styles/main';

.pickup-time-window-list-selector {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  .checkin-time-window {
    padding-left: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    height: 48px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }

  .checkin-time-window {
    padding-left: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    height: 48px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }

  .checkin-time-wrapper {
    padding: 15px 19px 0 15px;
    flex: 1;
    overflow: auto;
  }

  .alert-title {
    color: $color-text-primary;
    font-weight: $heavy-weight;
    font-size: rem(24px);
    margin: 15px 19px 0 15px;
  }

  .alert-desc {
    margin: 15px 19px 0 15px;
  }

  .popup-wrapper .content-wrapper {
    margin-top: 64px;
  }

  .popup-wrapper .content-wrapper {
    margin-top: 64px;
  }

  .button-wrapper {
    padding: 20px;
    button {
      flex-shrink: 0;
      width: 100%;
      height: 50px;
      padding: 0;
      cursor: pointer;
    }
  }
  .button-wrapper > * {
    margin-top: 8px;
  }
  .button-wrapper > *:first-child {
    margin-top: 0px;
  }
}
