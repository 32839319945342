@import '../../styles/main';

.billing-wrapper {
  .content {
    padding: rem(16px);
  }

  .billing-text {
    margin: 0 0 rem(15px) 0;
  }

  .billing-text,
  .order-pickup-text {
    font-family: $native;
    font-size: rem(16px);
    line-height: rem(24px);
  }

  .billing-text,
  .reminder-text,
  .reminder-sub-text {
    white-space: pre-line;
  }

  .reminder-sub-text {
    font-size: rem(12px);
    margin: 0;
  }
}
