/* Theme for Palette 11 */

$color-primary-11: #036a37;
$color-secondary-11: #6cc049;
$color-nav-11: #6cc049;
$color-accent-11: #000000;
$color-discount-11: #eb6522;
$color-error-11: #b11010;
$color-canvas-11: #e0dfdd;
$color-card-11: #ffffff;
$color-dark-highlight-11: #b9babb;
$color-light-highlight-11: #cacbcc;
$color-text-primary-11: #000000;
$color-text-secondary-11: #ffffff;
$color-text-tertiary-11: #000000;

$color-button-disabled-11: lighten($color-accent-11, 40%);

.theme-palette-11 {
  .color-primary {
    color: $color-primary-11;
  }
  .bg-color-primary {
    background-color: $color-primary-11;
  }

  .color-secondary {
    color: $color-secondary-11;
  }
  .bg-color-secondary {
    background-color: $color-secondary-11;
  }
  .border-color-secondary {
    border-color: $color-secondary-11;
  }

  .toast-text-color {
    color: $color-primary-11;
  }

  .toast-bg-color {
    background-color: $color-discount-11;
  }

  .color-nav,
  a.color-nav:link,
  a.color-nav:visited,
  a.color-nav:hover {
    color: $color-nav-11;
  }

  .color-nav-header,
  a.color-nav-header:link,
  a.color-nav-header:visited,
  a.color-nav-header:hover {
    color: $color-nav-11;
  }

  .color-checkmark {
    color: $color-primary-11;
  }

  .color-confirmation-icon {
    color: $color-light-highlight-11;
  }

  .color-confirmation-text {
    color: $color-text-primary-11;
  }

  .bg-color-nav {
    background-color: $color-nav-11;
  }
  .border-color-nav {
    border-color: $color-nav-11;
  }

  .color-accent {
    color: $color-accent-11;
  }
  .bg-color-accent,
  .bg-after-color-accent:after {
    background-color: $color-accent-11;
  }
  .border-color-accent {
    border-color: $color-accent-11;
  }
  .border-color-top-accent {
    border-top-color: $color-accent-11;
  }

  .color-discount {
    color: $color-discount-11;
  }
  .bg-color-discount {
    background-color: $color-discount-11;
  }

  .color-error {
    color: $color-error-11;
  }
  .bg-color-error {
    background-color: $color-error-11;
  }

  .color-canvas {
    color: $color-canvas-11;
  }
  .bg-color-canvas {
    background-color: $color-canvas-11;
  }

  .color-card {
    color: $color-card-11;
  }
  .bg-color-card,
  .content {
    background-color: $color-card-11;
  }

  .color-dark-highlight {
    color: $color-dark-highlight-11;
  }
  .bg-color-dark-highlight {
    background-color: $color-dark-highlight-11;
  }
  .border-color-dark-highlight {
    border-color: $color-dark-highlight-11;
  }

  .color-light-highlight {
    color: $color-light-highlight-11;
  }
  .bg-color-light-highlight {
    background-color: $color-light-highlight-11;
  }

  .color-text-primary {
    color: $color-text-primary-11;
  }

  .color-text-secondary {
    color: $color-text-secondary-11;
  }

  .color-text-tertiary,
  .title {
    color: $color-text-tertiary-11;
  }

  .color-disabled {
    color: $color-dark-highlight-11;
  }

  // Specific form elements
  button {
    color: $color-card-11;
    background-color: $color-accent-11;
  }
}

:export {
  color_primary_theme_11: $color-primary-11;
  color_secondary_theme_11: $color-secondary-11;
  color_nav_theme_11: $color-nav-11;
  color_accent_theme_11: $color-accent-11;
  color_discount_theme_11: $color-discount-11;
  color_error_theme_11: $color-error-11;
  color_canvas_theme_11: $color-canvas-11;
  color_card_theme_11: $color-card-11;
  color_dark_highlight_theme_11: $color-dark-highlight-11;
  color_light_highlight_theme_11: $color-light-highlight-11;
  color_text_primary_theme_11: $color-text-primary-11;
  color_text_secondary_theme_11: $color-text-secondary-11;
  color_text_tertiary_theme_11: $color-text-tertiary-11;
  color_button_disabled_theme_11: $color-button-disabled-11;
}
