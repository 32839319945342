@import '../../styles/main';

.spinner-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $spinner-background-color;
  z-index: 999;

  &.hide {
    display: none;
  }

  .spinner {
    border: 16px solid;
    border-color: $spinner-color2;
    border-top-color: $color-accent;
    border-radius: 50%;
    width: rem(48px);
    height: rem(48px);
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
