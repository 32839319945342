@import '../../styles/main';

.place {
  text-decoration: none !important;
  display: flex;
  min-height: 30px;
  padding: 10px;
  border-bottom: 1px solid gray;

  .label {
    padding-top: 6px;
    font-size: rem(22px);
    padding-bottom: 6px;
  }

  .sticker {
    padding-right: 20px;
    .place-image {
      width: 90px;
      height: 90px;
      border-radius: 60px;
    }
    .placeholder-image {
      border-width: 1px;
      border-style: solid;
    }
  }
  .info {
    display: flex;
    min-width: 0;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    cursor: pointer;
  }
  .tags {
    display: flex;
    text-decoration: none !important;
    .tag {
      display: flex;
      flex-wrap: nowrap;
      min-width: 0;
      .tag-label {
        font-size: rem(14px);
      }
      .tag-value {
        font-size: rem(16px);
        font-weight: bold;
      }
      .tag-image {
        padding-right: 6px;
        width: 36px;
        height: 36px;
        opacity: 0.3;
      }
      .tag-info {
        min-width: 0;
        white-space: nowrap;

        .ellipsis {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .tag:not(:last-child) {
      margin-right: 26px;
    }
  }

  .ptw {
    margin-top: rem(10px);

    .label,
    .value {
      font-size: rem(16px);
    }

    .time {
      color: $color-text-secondary;
      border-radius: rem(13px);
      padding: rem(3px) rem(10px);
    }
  }
}
