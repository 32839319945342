@import '../../styles/main';

.gratuity-wrapper {
  margin: rem(7px) rem(15px) rem(15px);

  h3 {
    margin: rem(15px) 0 rem(9px) !important;
  }

  .gratuity-options {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-left: rem(-6px);

    .gratuity-option {
      text-align: center;
      border-width: 1px;
      border-style: solid;
      padding: rem(8px) rem(16px);
      margin: rem(6px);
      min-width: rem(40px);
      cursor: pointer;

      .gratuity-percent {
        font-size: rem(12px);
        padding-bottom: rem(4px);
      }

      .gratuity-amount {
        font-size: rem(14px);
      }

      &.custom-amount {
        padding: rem(17px) rem(16px);
      }
    }
  }

  label {
    text-transform: uppercase;
    font-size: rem(12px);
    font-weight: $heavy-weight;
    margin: rem(12px) 0 rem(5px);
    opacity: 0.5;
  }

  .custom-amount-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    border-bottom: 1px solid #00000026;

    .currency-symbol {
      margin-right: rem(4px);
    }

    input {
      border: 0;
      width: 100%;
      line-height: normal; // Vertically center placeholder
      outline: none;
      background-color: transparent;
    }
  }

  .hide {
    display: none;
  }
}
