@import '../../../styles/main.scss';

.locker-code-status {
  .locker-code {
    font-size: rem(16px);
    font-weight: 700;
    margin-top: rem(16px);
  }

  .locker-placeholder-wrapper {
    overflow-y: hidden;
    overflow-x: visible;
    display: flex;

    .locker-placeholder {
      animation-name: slot-machine;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }

    .code-1 {
      animation-delay: 0s;
    }

    .code-2 {
      animation-delay: 0.2s;
    }

    .code-3 {
      animation-delay: 0.4s;
    }
  }

  @keyframes slot-machine {
    0% {
      transform: translateY(0%);
    }
    33% {
      transform: translateY(60%);
      opacity: 0;
    }
    66% {
      transform: translateY(-60%);
      opacity: 0;
    }
    67% {
      transform: translateY(-60%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  @keyframes float-down {
    from {
      transform: translateY(-60%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }
}
