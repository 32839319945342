@import './functions';
@import './variables';

.header-text {
  font-weight: $bold-weight;
  font-style: normal;
}

.header-secondary-text {
  font-weight: $regular-weight;
  font-style: normal;
}

.gray-header {
  display: flex;
  font-size: rem(14px);
  font-weight: $bold-weight;
  color: $header-color-base;
  margin: rem(16px) 0;
}

.secondary-text {
  font-size: rem(14px);
}

.gray-secondary-text {
  color: $header-color-secondary;
}

.uppercase {
  text-transform: uppercase;
}
