@import '../../styles/main.scss';

.order-details-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .icon-big {
    font-size: rem(48px);
    padding-right: rem(5px);
  }

  .icon-small {
    font-size: rem(24px);
    padding-right: rem(10px);
    vertical-align: middle;
  }

  .order-number-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: rem(25px) 0;

    .order-number-sub-header {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;

      .sub-header-order-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
    }

    span {
      text-align: center;
    }

    img {
      width: rem(48px);
      height: rem(48px);
    }

    .order-label {
      font-weight: $bold-weight;
      text-transform: uppercase;
      font-size: rem(14px);
      margin-bottom: rem(10px);
      margin-top: rem(10px);
      opacity: 0.5;
    }

    .title {
      opacity: 0.5;
    }

    .order-number {
      font-size: rem(36px);
    }

    .locker-placeholder {
      color: transparent;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
    }

    .order-text {
      margin: rem(16px) rem(24px) rem(16px) rem(24px);
      line-height: rem(24px);
      text-align: center;

      div {
        margin-left: rem(30px);
        margin-right: rem(30px);
      }
    }
  }

  .order-next-steps,
  .order-expired-next-steps {
    flex: 1;
    &.order-expired-next-steps {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 20px;
    }

    .new-order-button-wrapper {
      height: 52px;
      padding: 0 20px;

      button {
        flex-shrink: 0;
        width: 100%;
        height: 50px;
        padding: 0;
        cursor: pointer;
      }
    }
  }
}
