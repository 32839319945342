@import '../../../styles/main.scss';

.pickup-info-wrapper {
  .icon-small {
    font-size: rem(24px);
    padding-right: rem(10px);
    vertical-align: middle;
  }

  .prep-time {
    font-weight: bold;
  }

  .order-pickup-notes-wrapper {
    > div {
      display: flex;
      align-items: center;
      margin: rem(16px);
    }

    img {
      width: rem(24px);
      height: rem(24px);
      margin-right: rem(15px);
    }

    p {
      margin: 0;
      line-height: rem(25px);
    }
  }
}
