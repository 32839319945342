@import './functions';
@import './variables';

html {
  font-family: $default-font;
  font-weight: $regular-weight;
}

// Disable double-tap to zoom, but allow pan and pinch gestures.
html,
body {
  touch-action: manipulation;
}

footer {
  .footer_build {
    font-size: 9px;
    color: $text-color-gray;
    position: fixed;
    bottom: rem(1px);
    right: rem(30px);
    z-index: 300;
  }
  .hidden {
    display: none;
  }
}
