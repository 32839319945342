@import '../../styles/main';

// This gets the menu items out of the way of the banner
.toast-padding {
  position: relative;
  height: 35px;
}

.toast-wrapper {
  position: fixed;
  width: 100%;
  left: 0px;
  display: flex;
  background-color: $toast-bg-color;
  padding: rem(20px);
  height: rem(40px);
  box-sizing: border-box;
  align-items: center;
  z-index: 700;
  margin-top: calc(114px + constant(safe-area-inset-top));
  margin-top: calc(114px + env(safe-area-inset-top));

  .toast-text {
    font-family: $native;
    font-size: rem(13px);
    margin-left: rem(10px);
  }
}
