/* Theme for Palette 1 */

$color-primary-1: #092340;
$color-secondary-1: #278189;
$color-nav-1: #189ade;
$color-accent-1: #0080bb;
$color-discount-1: #cc9700;
$color-error-1: #bc4146;
$color-canvas-1: #f4f4f4;
$color-card-1: #ffffff;
$color-dark-highlight-1: #cccccc;
$color-light-highlight-1: #d6d6d6;
$color-text-primary-1: #092340;
$color-text-secondary-1: #ffffff;
$color-text-tertiary-1: #6a6c69;

$color-button-disabled-1: lighten($color-accent-1, 40%);

.theme-palette-1 {
  .color-primary {
    color: $color-primary-1;
  }
  .bg-color-primary {
    background-color: $color-primary-1;
  }

  .color-secondary {
    color: $color-secondary-1;
  }
  .bg-color-secondary {
    background-color: $color-secondary-1;
  }
  .border-color-secondary {
    border-color: $color-secondary-1;
  }

  .toast-text-color {
    color: $color-primary-1;
  }

  .toast-bg-color {
    background-color: $color-discount-1;
  }

  .color-nav,
  a.color-nav:link,
  a.color-nav:visited,
  a.color-nav:hover {
    color: $color-nav-1;
  }

  .color-nav-header,
  a.color-nav-header:link,
  a.color-nav-header:visited,
  a.color-nav-header:hover {
    color: $color-nav-1;
  }

  .color-checkmark {
    color: $color-primary-1;
  }

  .color-confirmation-icon {
    color: $color-light-highlight-1;
  }

  .color-confirmation-text {
    color: $color-text-primary-1;
  }

  .bg-color-nav {
    background-color: $color-nav-1;
  }
  .border-color-nav {
    border-color: $color-nav-1;
  }

  .color-accent {
    color: $color-accent-1;
  }
  .bg-color-accent,
  .bg-after-color-accent:after {
    background-color: $color-accent-1;
  }
  .border-color-accent {
    border-color: $color-accent-1;
  }
  .border-color-top-accent {
    border-top-color: $color-accent-1;
  }

  .color-discount {
    color: $color-discount-1;
  }
  .bg-color-discount {
    background-color: $color-discount-1;
  }

  .color-error {
    color: $color-error-1;
  }
  .bg-color-error {
    background-color: $color-error-1;
  }

  .color-canvas {
    color: $color-canvas-1;
  }
  .bg-color-canvas {
    background-color: $color-canvas-1;
  }

  .color-card {
    color: $color-card-1;
  }
  .bg-color-card,
  .content {
    background-color: $color-card-1;
  }

  .color-dark-highlight {
    color: $color-dark-highlight-1;
  }
  .bg-color-dark-highlight {
    background-color: $color-dark-highlight-1;
  }
  .border-color-dark-highlight {
    border-color: $color-dark-highlight-1;
  }

  .color-light-highlight {
    color: $color-light-highlight-1;
  }
  .bg-color-light-highlight {
    background-color: $color-light-highlight-1;
  }

  .color-text-primary {
    color: $color-text-primary-1;
  }

  .color-text-secondary {
    color: $color-text-secondary-1;
  }

  .color-text-tertiary,
  .title {
    color: $color-text-tertiary-1;
  }

  .color-disabled {
    color: $color-dark-highlight-1;
  }

  // Specific form elements
  button {
    color: $color-card-1;
    background-color: $color-accent-1;
  }
}

:export {
  color_primary_theme_1: $color-primary-1;
  color_secondary_theme_1: $color-secondary-1;
  color_nav_theme_1: $color-nav-1;
  color_accent_theme_1: $color-accent-1;
  color_discount_theme_1: $color-discount-1;
  color_error_theme_1: $color-error-1;
  color_canvas_theme_1: $color-canvas-1;
  color_card_theme_1: $color-card-1;
  color_dark_highlight_theme_1: $color-dark-highlight-1;
  color_light_highlight_theme_1: $color-light-highlight-1;
  color_text_primary_theme_1: $color-text-primary-1;
  color_text_secondary_theme_1: $color-text-secondary-1;
  color_text_tertiary_theme_1: $color-text-tertiary-1;
  color_button_disabled_theme_1: $color-button-disabled-1;
}
