.tooltip {
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 22px;
  padding: 6px;

  background-color: #fff;
  border: 1px solid #7d8898;
  z-index: 1000;
  width: 200px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  pointer-events: none;

  &.show {
    opacity: 1;
    transition: opacity 0.3s ease;
    pointer-events: all;
  }

  &-title {
    margin: 0;
    padding-bottom: 4px;
  }

  &-body {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.25;
    margin: 6px 0;
  }
}
